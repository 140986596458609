<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>切换门店：</p>
          <select v-model="form.fenpeimendianId" @change="getData(1)">
            <option :value="0">全部门店</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>操作时间：</p>
          <input type="date" @change="getData(1)" v-model="form.startDate"><span> &nbsp;-&nbsp; </span>
          <input type="date" @change="getData(1)" v-model="form.endDate">
        </li>
        <li>
          <p>快捷查询：</p>
          <select v-model="Sdate" @change="addDate">
            <option value="0">全部</option>
            <option value="D">今日</option>
            <option value="W">本周</option>
            <option value="M">本月</option>
            <option value="NM">上月</option>
          </select>
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <colgroup>

        </colgroup>
        <thead>
          <tr>
            <td>退货时间</td>
            <td>门店</td>
            <td>退货人</td>
            <td>退货商品</td>
            <td style="text-align:center">退货单价</td>
            <td style="text-align:center">退货数量</td>
            <td style="text-align:center">状态</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td>{{dataFormat(item.tuihuoshijian)}}</td>
            <td>{{item.fenpeimendianName}}</td>
            <td>{{item.tuihuorenName}}</td>
            <td>{{item.comName}}</td>
            <td style="text-align:center">{{item.tuihuodanjia}}</td>
            <td style="text-align:center"><span class="err">{{item.num}}</span></td>
            <td style="text-align:center"><span :class="returnLBClass(item.state)">{{returnLB(item.state)}}</span></td>
            <td>
              <div class="buttonGroup">
                <button type="button" class="suc" @click="setInfoData(item)">详情</button>
                <button v-if="item.state==1" type="button" class="err" @click="delOneData(item.id,item.comName,item.num,item.richangId,item.comId,item.tuihuodanjia)" v-pow:5501>撤销</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <PSinfo :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></PSinfo>
  </div>
</template>

<script>
import axios from '@/http/axios'
import PSinfo from './PSinfo.vue'
import moment from 'moment'
export default {
  name: 'inventoryInRecord',
  data() {
    return {
      title:'',
      infoShow: false,
      dataList:[],
      storeList:[],
      form:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        startDate:'',
        endDate:'',
        comId:this.$route.query.comId,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      Sdate:0,
      MmsData:{
        new:true,
      },
    }
  },
  created() {
    this.getStore()
  },
  components: {PSinfo},
  mounted() {},
  methods: {
    addDate(){
      let date =this.Sdate
      let s= moment(), e= moment()
      if(date==0){
        s=''
        e=''
      }
      else if(date=="NM"){
        s=s.subtract(1,"M").startOf('M').format("YYYY-MM-DD")
        e=e.subtract(1,"M").endOf('M').format("YYYY-MM-DD")
      }else{
         s= s.startOf(date).format("YYYY-MM-DD")
         e= e.endOf(date).format("YYYY-MM-DD")
      }
      this.form.startDate=s
      this.form.endDate=e
      this.getData(1)
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
          this.getData()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    dataFormat(str){
      if(str) return moment(str).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    returnLBClass(lb){
      if(lb==1){
        return 'suc'
      }
      else {
        return 'err'
      }
    },
    returnLB(lb){
      if(lb==1){
        return '正常'
      }
      else{
        return '撤销'
      }
    },
    delOneData(id,name,num,richangId,comId,tuihuodanjia){
      this.$trueOrFalse({
        title: '撤销提示',
        content: `确认撤销 ${name} 这条退货记录?`,
        torf: false,
        suc:()=>{
          axios.post('/commity/delTHJL',{id,num,comId,tuihuodanjia,richangId}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/record/inventoryOutRecord',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          res.data.forEach(e => {
            let store=this.storeList.find(a=>a.id==e.fenpeimendianId)
            if(store) e.fenpeimendianName=store.storeName
            else e.fenpeimendianName="门店已删"
          });
          this.dataList=res.data
          this.pageData.total=res.total
        }
      }).catch(() => {
        this.$Mesg({
          content: '获取数据失败！',
          errOrSuc: 'err',
        })
      })
    },
    setInfoData(item){
      this.title=item.comName
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    },
    closedWindow(){
      this.infoShow=false
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    
  },
}
</script>
