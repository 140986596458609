<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span>门店：</span>{{MmsData.data.fenpeimendianName}}</li>
            <li class="up"><span>退货时间：</span>{{getDateTime(MmsData.data.tuihuoshijian)}}</li>
            <li class="up"><span>退货人：</span>{{MmsData.data.tuihuorenName}}</li>
            <li class="up"><span>退货商品：</span>{{MmsData.data.comName}}</li>
            <li class="up"><span>退货单价：</span>{{MmsData.data.tuihuodanjia}}</li>
            <li class="up"><span>退货数量：</span>{{MmsData.data.num}} </li>
            <li class="up"><span>单位：</span>{{MmsData.data.unit}} </li>
            <li class="up"><span>状态：</span>{{MmsData.data.state==1?'正常':'撤销'}}</li>
            <template v-if="MmsData.data.resetId">
              <li class="up"><span>撤销时间：</span>{{getDateTime(MmsData.data.resetTime)}}</li>
              <li class="up"><span>撤销人：</span>{{MmsData.data.resetName}}</li>
            </template>
          </ul>
          <div class="center_b_4">
            <button class="settab" v-if="MmsData.data.state==1" @click="delOneData(MmsData.data.id,MmsData.data.comName,MmsData.data.num,MmsData.data.richangId,MmsData.data.comId,MmsData.data.tuihuodanjia)" v-pow:5501>撤销</button>
            <button class="remole" @click="closedWindow">关闭</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>

<script>
import axios from '@/http/axios'
import moment from 'moment'
export default {
  name: 'PSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  data() {
    return {
    }
  },
  created() {},
  components: {},
  mounted() {
  },
  methods: {
    delOneData(id,name,num,richangId,comId,tuihuodanjia){
      this.$trueOrFalse({
        title: '撤销提示',
        content: `确认撤销 ${name} 这条退货记录?`,
        torf: false,
        suc:()=>{
          axios.post('/commity/delTHJL',{id,num,richangId,comId,tuihuodanjia}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.$parent.getData(1)
              this.closedWindow()
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    getDateTime(str){
      if(str) return moment(str).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
  },
}
</script>
